import { lazy } from 'react'
import { Navigate, createBrowserRouter } from 'react-router-dom'

export const ROUTES = {
  HOME: { INDEX: '/' },
}

const Download = lazy(() => import('@/pages/download'))
const Doc = lazy(() => import('@/pages/doc'))
const DocIos = lazy(() => import('@/pages/docIos'))

export const router = createBrowserRouter([
  { path: ROUTES.HOME.INDEX, element: <Download /> },
  { path: '*', element: <Navigate to={ROUTES.HOME.INDEX} /> },
  { path: '/Doc', element: <Doc></Doc> },
  { path: '/DocIos', element: <DocIos></DocIos> },
])
