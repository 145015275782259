//import 'babel-polyfill';
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { router } from '@/routes'
import 'core-js'
import { ThemeProvider as NextThemesProvider } from 'next-themes'

import { NextUIProvider } from '@nextui-org/react'

import '@/styles/index.css'

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replacement) {
    return this.split(search).join(replacement)
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme="dark">
        <Suspense fallback={<></>}>
          <RouterProvider router={router} />
        </Suspense>
      </NextThemesProvider>
    </NextUIProvider>
  </React.StrictMode>,
)
